@import url(https://fonts.googleapis.com/css?family=Baloo|Baloo+Chettan+2|Montserrat&display=swap);
@media screen and (min-width: 300px) {
  * {
    box-sizing: border-box;
  }
  body {
    margin: 0;
    font-family: "Montserrat", sans-serif;
  }
  ::-webkit-scrollbar {
    display: none;
  }
  a {
    color: #195faa;
    -webkit-text-decoration: #195faa;
            text-decoration: #195faa;
  }
  a:visited {
    color: #195faa;
  }
  ul {
    list-style: none;
  }
  nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 50px;
    display: flex;
    background-color: #195faa;
    padding-left: 10px;
    z-index: 1;
  }
  #nav-left,
  #nav-right,
  #nav-right-logout {
    display: flex;
    flex-direction: row;
    width: 50%;
    align-items: center;
    font-family: "Baloo", cursive;
  }
  #nav-left a,
  #nav-right a,
  #nav-right-logout a {
    color: white;
  }
  i {
    margin-right: 5px;
  }
  .fa-taxi {
    margin-right: 5px;
    font-size: 22px;
  }
  .app-name {
    font-size: 22px;
  }
  svg {
    margin-right: 10px;
  }
  #nav-right {
    align-items: center;
    justify-content: space-evenly;
    font-size: smaller;
  }
  #nav-right-logout {
    justify-content: flex-end;
    padding-right: 20px;
    font-size: smaller;
  }
  #nav-right-logout a {
    margin: 0 5px 0 5px;
  }
  .section-one {
    height: 100vh;
    background: #bfdeff;
    background: linear-gradient(to right, #f0f6fc, #bfdeff, #d3e4f7);
    background-size: 400% 400%;
    -webkit-animation: gradient 15s ease infinite;
            animation: gradient 15s ease infinite;
    margin-bottom: 10%;
  }
  @-webkit-keyframes gradient {
    0% {
      background-position: 0%;
    }
    50% {
      background-position: 100%;
    }
    100% {
      background-position: 0%;
    }
  }
  @keyframes gradient {
    0% {
      background-position: 0%;
    }
    50% {
      background-position: 100%;
    }
    100% {
      background-position: 0%;
    }
  }
  .section-one h1 {
    margin: 0;
    font-size: 2.4em;
    font-family: "Baloo", cursive;
    color: #ff4e40;
  }
  .hero-container {
    display: flex;
  }
  .hero-icon-container {
    margin-left: 20px;
    align-self: center;
  }
  .hero-icon {
    font-size: 7em;
    margin-left: auto;
    margin-right: auto;
    color: #195faa;
  }
  .hero-text-container {
    margin: 40px 0 40px 20px;
  }
  .hero-text-container h1 {
    letter-spacing: 2.5px;
  }
  .section-one h3 {
    margin-top: 0;
    margin-bottom: 5px;
    font-size: small;
    color: #195faa;
  }
  .section-one div {
    padding-bottom: 15px;
    text-align: left;
    font-size: small;
  }
  .fade-in-section {
    opacity: 0;
    -webkit-transform: translateY(15vh);
            transform: translateY(15vh);
    visibility: hidden;
    transition: opacity 0.6s ease-out, -webkit-transform 1s ease-out;
    transition: opacity 0.6s ease-out, transform 1s ease-out;
    transition: opacity 0.6s ease-out, transform 1s ease-out, -webkit-transform 1s ease-out;
    will-change: opacity, visibility;
  }
  .fade-in-section.is-visible {
    opacity: 1;
    -webkit-transform: none;
            transform: none;
    visibility: visible;
  }
  .section-two,
  .section-three {
    border-bottom: 1px solid lightgrey;
  }
  .section-two h1,
  .section-three h1,
  .section-four h1 {
    padding: 0 40px;
    color: #195faa;
  }
  .main-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    margin-bottom: 60px;
    padding: 10px;
    text-align: center;
  }
  .fa-calendar-week,
  .fa-plane,
  .fa-cloud-sun-rain {
    margin-right: auto;
    margin-left: auto;
    font-size: 60px;
  }
  .fa-cloud-sun-rain {
    color: #ff4e40;
  }
  .fa-plane {
    color: #268fff;
  }
  .fa-calendar-week {
    color: #9eb312;
  }
  .main-section p {
    margin-top: 0;
    padding: 0 40px 60px 40px;
  }
  button {
    position: relative;
    display: inline-block;
    cursor: pointer;
    outline: none;
    border: 0;
    vertical-align: middle;
    text-decoration: none;
    background: transparent;
    padding: 0;
    font-family: "Baloo", cursive;
    margin-left: auto;
    margin-right: auto;
  }
  button.btn {
    width: 12rem;
    height: auto;
  }
  button.btn .circle {
    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
    position: relative;
    display: block;
    margin: 0;
    width: 3rem;
    height: 3rem;
    background: #195faa;
    border-radius: 4px;
  }
  button.btn .circle .icon {
    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    background: #fff;
  }
  button.btn .circle .icon.arrow {
    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
    left: 0.625rem;
    width: 1.125rem;
    height: 0.125rem;
    background: none;
  }
  button.btn .circle .icon.arrow::before {
    position: absolute;
    content: "";
    top: -0.3rem;
    right: 0.0625rem;
    width: 0.625rem;
    height: 0.625rem;
    border-top: 0.125rem solid #fff;
    border-right: 0.125rem solid #fff;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  button.btn .button-text {
    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0.75rem 0;
    margin: 0 0 0 1.85rem;
    font-weight: inherit;
    line-height: 1.6;
    text-align: center;
    color: #195faa;
    text-transform: uppercase;
  }
  button:hover .circle {
    width: 100%;
    background-color: #ff4e40;
  }
  button:hover .circle .icon.arrow {
    background: #fff;
    -webkit-transform: translate(1rem, 0);
    transform: translate(1rem, 0);
  }
  button:hover .button-text {
    color: #fff;
  }
  .login-main {
    margin-top: 35%;
  }
  .signup-main {
    margin: 20% 0;
  }
  form {
    display: flex;
    flex-direction: column;
    padding: 0 20px;
  }
  input:-webkit-autofill {
    box-shadow: 0 0 0 30px white inset;
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }
  input[type="email"],
  input[type="password"],
  input[type="text"] {
    width: 100%;
    padding: 10px 0 10px 5px;
    letter-spacing: 1.4px;
    border-bottom: 1px solid lightgrey;
    border-top: none;
    border-right: none;
    border-left: none;
  }
  input:focus {
    outline: none;
    box-shadow: 0 0 5px #195faa;
  }
  input[type="email"],
  input[type="text"] {
    margin-bottom: 15px;
  }
  .button-container {
    margin: 0 auto;
  }
  input[type="submit"] {
    display: block;
    text-align: center;
    text-decoration: none;
    font-family: "Montserrat", sans-serif;
    font-weight: bolder;
    text-transform: uppercase;
    color: #fff;
    border: none;
    border-radius: 6px;
    margin: 10px;
    padding: 1em 3em;
    background-size: 300% 300%;
    color: white;
    background-image: linear-gradient(
      to right,
      #195faa 0%,
      #4592e4 50%,
      #195faa 100%
    );
    transition: 0.5s;
  }
  input:hover {
    background-position: right center;
  }
  .form-header {
    padding-bottom: 20px;
    font-size: 0.8em;
    color: red;
  }
  .show-container {
    display: flex;
    align-items: center;
    font-size: 0.7em;
    margin-bottom: 30px;
  }
  input[type="checkbox"] {
    width: unset;
    margin: 10px 5px 10px 0;
  }
  .signup-link {
    margin: 30px 0;
    text-align: center;
    font-size: 0.8em;
  }
  .bold-link {
    display: block;
    font-weight: bolder;
    margin: 10px;
  }

  .bold-link a:hover {
    color: #ff4e40;
    border-bottom: 1px solid #ff4e40;
  }
  .dashboard-main {
    margin-top: 15%;
    margin-bottom: 60px;
    padding: 1px 0 15px 0;
    -webkit-animation: fadeInAnimation ease 2s;
            animation: fadeInAnimation ease 2s;
    -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
  }
  @-webkit-keyframes fadeInAnimation {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes fadeInAnimation {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  .dashboard-main h1 {
    margin-left: 30px;
    margin-right: 30px;
    padding-bottom: 10px;
    font-family: "Baloo", cursive;
    font-weight: 100;
    color: #0c2d51;
    border-bottom: 1px solid lightgrey;
  }
  .loader {
    margin-top: 50%;
    margin-left: auto;
    margin-right: auto;
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #195faa;
    width: 120px;
    height: 120px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
  }
  .load-container p {
    text-align: center;
  }
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  @keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  .weather-container,
  .arrival-container,
  .departure-container,
  .events-container {
    padding: 10px 30px;
    background-color: white;
  }
  .weather-container {
    text-align: center;
  }
  .weather-container ul {
    display: flex;
    justify-content: space-evenly;
    padding: 0;
  }
  ul {
    margin: 0;
  }
  li img {
    width: 50px;
  }
  li p {
    font-size: 0.5em;
  }
  .temp-display {
    font-size: 0.9em;
    font-weight: bolder;
  }
  .dashboard-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    border-bottom: 1px solid lightgrey;
  }
  .fa-plane-arrival,
  .fa-plane-departure {
    color: #268fff;
  }
  .fa-calendar-alt {
    color: #9eb312;
  }
  .dashboard-header-events {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    border-bottom: 1px solid lightgrey;
  }
  .dashboard-header-events .fa-w-20 {
    flex: 1 1;
  }
  .dashboard-header p {
    font-size: 0.8em;
    flex: 8 1;
  }
  .dashboard-header-events p {
    font-size: 0.8em;
    flex: 8 1;
  }
  .dashboard-header h4 {
    margin-right: 10px;
  }
  .dashboard-header-events h4 {
    margin-right: 10px;
  }
  .chev-rotate-true {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  .flight-count {
    font-size: 0.7em;
  }
  .table-display-false,
  .events-display-false {
    display: none;
  }
  .airline-container h4 {
    margin-top: 0;
    margin-bottom: 0;
  }
  table {
    table-layout: fixed;
    width: 100%;
    font-size: 0.5em;
  }
  th {
    background-color: rgb(39, 143, 255, 0.2);
    border-bottom: 1px solid lightgrey;
  }
  th,
  td {
    text-align: left;
  }
  td {
    padding-right: 10px;
  }
  .flight-count {
    font-size: 0.7em;
  }
  .svg-inline--fa.calendar-header {
    width: 20px;
  }
  .event {
    clear: left;
    margin-bottom: 5px;
    border-bottom: 1px solid lightgray;
  }
  .event-img {
    float: left;
    margin-right: 10px;
    padding: 10px 0 10px 0;
  }
  .event-img img {
    width: 48px;
    height: 48px;
  }
  .stock-img {
    float: left;
    font-size: 55px;
  }
  .event-info {
    padding: 10px 0 20px 0;
    font-size: 0.5em;
  }
  footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 60px;
    background-color: #195faa;
  }
}
@media screen and (min-width: 768px) {
  #nav-right {
    justify-content: flex-end;
  }
  #nav-right a {
    padding: 0 20px;
  }
  .hero-container {
    margin-left: auto;
    margin-right: auto;
  }
  .hero-text-container {
    max-width: 250px;
  }
  .section-two,
  .section-three,
  .section-four {
    /* padding: 0 160px; */
  }
  .login-main {
    width: 600px;
    margin-top: 25%;
    margin-left: auto;
    margin-right: auto;
  }
  .signup-main {
    width: 600px;
    margin-top: 25%;
    margin-left: auto;
    margin-right: auto;
  }
  .dashboard-main {
    width: 600px;
    margin-top: 5%;
    margin-right: auto;
    margin-left: auto;
  }
  li img {
    width: unset;
  }
  li p {
    font-size: 1em;
  }
  .dashboard-header p {
    font-size: 1em;
  }
  .fa-plane-arrival,
  .fa-plane-departure,
  .svg-inline--fa.calendar-header {
    font-size: 2em;
    width: 48px;
    height: 48px;
  }
  table {
    font-size: 1em;
  }
  th,
  td {
    padding-bottom: 10px;
  }
  .event-info a,
  .event-info p {
    font-size: 1.5em;
  }
  .loader {
    margin-top: 25%;
  }
}
@media screen and (min-width: 1024px) {
  #nav-left a:hover {
    color: #ff4e40;
  }
  #nav-right a:hover {
    color: #ff4e40;
  }
  .section-one {
    margin-bottom: 180px;
  }
  .hero-icon {
    margin-left: 120px;
    margin-right: 20px;
    font-size: 10em;
  }
  .hero-text-container {
    max-width: 400px;
  }
  .hero-text-container h1 {
    letter-spacing: 10px;
    font-size: 4em;
  }
  .hero-text-container h3,
  .hero-text-container div {
    font-size: 1.5em;
  }
  .login-main {
    margin-top: 15%;
  }
  .signup-main {
    margin-top: 10%;
  }
  .dashboard-main {
    min-width: 800px;
  }
  .loader {
    margin-top: 20%;
  }

  .fa-chevron-down:hover {
    cursor: pointer;
  }
}

